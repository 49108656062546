import { Helmet } from "react-helmet";
import Header from "../Header/Header";

import "../Snack/Snack.css";

const BabyBabblePrivacy = () => {
  return (
    <>
      <Helmet>
        <title>SDD - Baby Babble EULA</title>
        <meta
          name="description"
          content="Snack Driven Development, Baby Babble EULA"
        />
      </Helmet>
      <div className="App">
        <Header showHome={true} />
        <h2>EULA</h2>
        <div className="snack">
          <div className="snack-card">
            <h2>Baby Babble EULA</h2>
            <p>
              We use Apples standard EULA:
              <br />
              <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/">
                https://www.apple.com/legal/internet-services/itunes/dev/stdeula/
              </a>
            </p>
            <p>This policy is effective as of December 28th 2023</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BabyBabblePrivacy;
