import { Helmet } from "react-helmet";
import Header from "../Header/Header";

import "../Snack/Snack.css";

const BabyBabblePrivacy = () => {
  return (
    <>
      <Helmet>
        <title>SDD - Baby Babble Privacy Policy</title>
        <meta
          name="description"
          content="Snack Driven Development, Baby Babble Privacy Policy"
        />
      </Helmet>
      <div className="App">
        <Header showHome={true} />
        <h2>Privacy Policy</h2>
        <div className="snack">
          <div className="snack-card">
            <h2>Baby Babble Privacy Policy</h2>
            <p>
              Your privacy is important to us, our policy is simple. Baby Babble
              does not collect data.
            </p>
            <p>
              That's it. All data is stored on your device. If you backup the
              app to iCloud it's stored there as well. See Apples privacy policy
              for more info.
            </p>
            <p>
              Your continued use of the app will be regarded as acceptance of
              the practices around privacy and personal information. If you have
              any questions about how we handle user data and/or personal
              information, feel free to contact us.
            </p>
            <p>This policy is effective as of December 28th 2023</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BabyBabblePrivacy;
