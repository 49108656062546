import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App/App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import About from "./About/About";
import { SnackContextProvider } from "./SnackContext";
import BabyBabblePrivacy from "./BabyBabble/BabyBabblePrivacy";
import BabyBabbleEULA from "./BabyBabble/BabyBabbleEULA";
import BabyBabble from "./BabyBabble/BabyBabble";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/babble/privacy",
    element: <BabyBabblePrivacy />,
  },
  {
    path: "/babble/eula",
    element: <BabyBabbleEULA />,
  },
  {
    path: "/babble/",
    element: <BabyBabble />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SnackContextProvider>
      <RouterProvider router={router} />
    </SnackContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
