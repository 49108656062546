import "./Snack.css";

const Snack = ({
  snack,
  locationURL,
  locationTitle,
  locationDescription,
  commitTitle,
  commitDescription,
  commitLink,
  snackNumber,
  snackDescription,
  images,
}) => {
  return (
    <div className="snack">
      <div className="snack-card">
        <h2>{commitTitle}</h2>
        <p>{commitDescription}</p>
        {images != null ? (
          <div class="scroll-container">
            {images.map((image) => (
              <img src={image.filename} alt={image.alt} />
            ))}
          </div>
        ) : null}
        <b>Snacks:</b> {snack} {snackDescription}
        <br />
        <b>Developed at:</b>{" "}
        <a href={locationURL} target="__blank">
          {locationTitle}
        </a>
        <p>
          Snack # {snackNumber} ·{" "}
          {commitLink != null ? (
            <a href={commitLink} target="__blank">
              App
            </a>
          ) : (
            "Coming Soon"
          )}{" "}
          ·{" "}
          <a href={locationURL} target="__blank">
            Cafe
          </a>
        </p>
      </div>
    </div>
  );
};

export default Snack;
