import Header from "../Header/Header";

import "../Snack/Snack.css";
import Snack from "../Snack/Snack";
import { Helmet } from "react-helmet";

const BabyBabble = () => {
  return (
    <>
      <Helmet>
        <title>SDD - Baby Babble</title>
        <meta
          name="description"
          content="Snack Driven Development, Baby Babble Privacy Policy"
        />
      </Helmet>
      <div className="App">
        <Header showHome={true} />
        <h2>Baby Babble</h2>
        <Snack
          commitTitle="Baby Babble"
          commitDescription={
            <>
              Ever wish you could:
              <ul>
                <li>
                  Go back in time and listen to your little ones first words?
                </li>
                <li>
                  Hear the progression of "ba", to "baba" to "bottle" and
                  everything in between
                </li>
                <li>
                  Make a playlist of all the times they ever said "I love you"
                </li>
                <li>
                  Keep track of all the words your child knows? (great for those
                  doctor visits)
                </li>
              </ul>
              Hey guess what, you're not alone! My wife did too, and so Baby
              Babble was born.
              <br />
              We've been using Baby Babble to track, re-live, and share those
              special moments with others.
              <br />
              Nothing would make us happier than helping other families do the
              same.
              <br />
              <br />
              <a href="/babble/privacy">Privacy Policy</a>{" "}
              <a href="/babble/eula">EULA</a>
            </>
          }
          commitLink="https://apps.apple.com/us/app/baby-babble/id6451130943"
          snackDescription="Croissants, cupcakes, and witches brew coffee!"
          snack="🧁🧙‍♀️"
          locationTitle="Alma Coffee"
          locationDescription=""
          locationURL="https://myalmacoffee.com"
          snackNumber="4"
        />
      </div>
    </>
  );
};

export default BabyBabble;
