import Snack from "../Snack/Snack";
import Header from "../Header/Header";
import { Link } from "react-router-dom";

import "./App.css";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <title>Snack Driven Development</title>
        <meta
          name="description"
          content="Snack Driven Development. 1. An hour or less. 2. Ship whatever you create. 3. Tasty drinks and snacks required. Snack sized coding sessions. Snack sized projects."
        />
      </Helmet>
      <div className="App">
        <Header />
        <h2>Latest Snack</h2>
        <Snack
          commitTitle="Baby Babble"
          commitLink="https://apps.apple.com/us/app/baby-babble/id6451130943"
          commitDescription={
            <>
              Ever wish you could:
              <ul>
                <li>
                  Go back in time and listen to your little ones first words?
                </li>
                <li>
                  Hear the progression of "ba", to "baba" to "bottle" and
                  everything in between
                </li>
                <li>
                  Make a playlist of all the times they ever said "I love you"
                </li>
                <li>
                  Keep track of all the words your child knows? (great for those
                  doctor visits)
                </li>
              </ul>
              Hey guess what, you're not alone! My wife did too, and so Baby
              Babble was born.
              <br />
              We've been using Baby Babble to track, re-live, and share those
              special moments with others.
              <br />
              Nothing would make us happier than helping other families do the
              same.
              <br />
              <br />
              <a href="/babble/">More info</a>
            </>
          }
          snackDescription="Croissants, cupcakes, and witches brew coffee!"
          snack="🧁🧙‍♀️"
          locationTitle="Alma Coffee"
          locationDescription=""
          locationURL="https://myalmacoffee.com"
          snackNumber="4"
          images={[
            {
              filename: "images/babble/play.jpg",
              alt: "Playing a word from baby babble",
            },
            {
              filename: "images/babble/view.jpeg",
              alt: "Viewing all words in baby babble",
            },
            {
              filename: "images/babble/add.jpeg",
              alt: "Adding a word to baby babble",
            },
            {
              filename: "images/babble/words.jpeg",
              alt: "Viewing all words in baby babble",
            },
            {
              filename: "images/babble/addbaby.jpg",
              alt: "Adding a baby to baby babble",
            },
            {
              filename: "images/babble/babies.jpg",
              alt: "Viewing all babies",
            },
          ]}
        />
        <h2>Previous Snacks</h2>
        <Snack
          commitTitle="WOW HC Screen Recorder"
          commitDescription={
            <>
              A Mac app for recording deaths in World of Warcraft. I lost a toon
              playing HC b/c of some crummy hotel Wi-Fi. If it was recorded it
              could have been fought. It's basically Nvidia Shadowplay but for
              Mac.
              <br />
              <br />
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/wow-hc-screen-recorder/id6450030690"
                rel="noreferrer"
              >
                Mac App Store
              </a>{" "}
              -{" "}
              <a
                target="_blank"
                alt="Link to the free version of WOW HC Screen recorder"
                href="https://github.com/jamesbouker/WOW-HC-Screen-Recorder"
                rel="noreferrer"
              >
                GitHub
              </a>
            </>
          }
          commitLink="https://apps.apple.com/us/app/wow-hc-screen-recorder/id6450030690"
          snackDescription="Cookies & Boba"
          snack="🍪🥤"
          locationTitle="Sweet Hut"
          locationDescription=""
          locationURL="https://www.google.com/maps/place/Sweet+Hut+Bakery+%26+Cafe/@34.0198359,-84.5541556,15z/data=!4m6!3m5!1s0x88f514dace67e9ab:0x5f31c1c0d60fc1e6!8m2!3d34.0198359!4d-84.5541556!16s%2Fg%2F11d_7ssjyr?entry=ttu"
          snackNumber="3"
        />
        <Snack
          commitTitle="Pico-7 Minute Workout"
          commitDescription={
            <>
              A workout app, built w/ Pico-8, the coolest thing ever made
              (Pico-8 not the workout app). Anyway, it's the classic 7 minute
              workout, but free, and on itchio, and w/ nice colors!
              <br />
              <br />
              Read more on the{" "}
              <a
                target="_blank"
                href="https://jimmybouker.itch.io/pico-7-minute-workout/devlog/488421/pico-7-minute-workout"
                rel="noreferrer"
              >
                Devlog
              </a>
              . Or{" "}
              <a
                target="_blank"
                alt="Link to the 7 minute workout app on itch.io"
                href="https://jimmybouker.itch.io/pico-7-minute-workout"
                rel="noreferrer"
              >
                try the workout
              </a>
              .
            </>
          }
          commitLink="https://jimmybouker.itch.io/pico-7-minute-workout"
          snackDescription="Coffee & Coffee"
          snack="☕️☕️"
          locationTitle="Barnes & Nobles - Starbucks"
          locationDescription=""
          locationURL="https://stores.barnesandnoble.com/store/1955"
          snackNumber="2"
          images={[
            {
              filename: "images/pico7/pico7_44.png",
              alt: "pico 7 minute workout icon",
            },
            {
              filename: "images/pico7/pico7_45.png",
              alt: "pico 7 minute workout background, list of exercises",
            },
            {
              filename: "images/pico7/pico7_12.png",
              alt: "plank exercise",
            },
            {
              filename: "images/pico7/pico7_42.png",
              alt: "lunges exercise",
            },
            {
              filename: "images/pico7/pico7_1.png",
              alt: "wall sits exercise",
            },
          ]}
        />
        <Snack
          commitTitle="Snack Driven Development Site"
          commitDescription={`Came up w/ snack driven development today, bit of a "lightbulb moment". However, I had planned the first snack session to be a tiny workout app. Not this website. After a quick google, both the domain and twitter were available. Procrastination at its finest!`}
          commitLink="https://snackdriven.dev"
          snackDescription="Chocolate Croissant & Dark Mocha Latte"
          snack="🍫🥐"
          locationTitle="Cool Beans Coffee Roasters"
          locationDescription=""
          locationURL="https://www.facebook.com/CoolBeansCoffee/"
          snackNumber="1"
        />
        <footer style={{ marginBottom: "1rem" }}>
          Enjoy snacks and making things?{" "}
          <Link to="/about">Give it a try!</Link>
        </footer>
      </div>
    </>
  );
}

export default App;
