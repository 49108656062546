// import { Link } from "react-router-dom";
import Header from "../Header/Header";

import "../Snack/Snack.css";
import "./About.css";

const About = () => {
  return (
    <div className="App">
      <Header showHome={true} />
      <h2>???</h2>
      <div className="snack">
        <div className="snack-card">
          <h2>What is all this?</h2>
          <p>
            As I got older, finding time to work on hobby projects became quite
            difficult. Less time coupled with an ever increasing bar for what I
            thought was shippable. Releasing hobby projects became impossible.
            And with that, I lost one of my greatest sources of happiness.
          </p>
          <h3>The Solution?</h3>
          <p>
            Snack Driven Development. <br />
            Snack sized coding sessions. Snack sized apps.
            <br />
            Incrementally building to something, or not.
            <br />
            At the very least the there will be treats.
          </p>
          <h3>The rules</h3>
          <p>
            1. An hour or less
            <br />
            2. Ship whatever you create
            <br />
            3. Tasty drink and snacks required
          </p>
          {/* <Link to="/signup">Start snacking</Link> */}
        </div>
      </div>
    </div>
  );
};

export default About;
