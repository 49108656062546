import { Link } from "react-router-dom";
import "./Header.css";

const Header = ({ showHome }) => {
  return (
    <header
      style={{
        paddingBottom: "1rem",
        borderBottom: "1px solid black",
      }}
    >
      <div className="outer-container">
        <div className="header-container">
          <Link style={{ textDecoration: "none" }} to="/">
            <h1 style={{ marginBottom: 0 }}>🥛 🍪 Snack Driven Development</h1>
          </Link>
          <div style={{ display: "flex" }}>
            {/* {showLogin ? (
              <>
                <Link to="/login">Login</Link>
                {" - "}
              </>
            ) : (
              <>
                <Link to="/signup">Signup</Link>
                {" - "}
              </>
            )} */}
            {showHome && (
              <>
                <Link className="header-link" to="/">
                  Snacks
                </Link>{" "}
              </>
            )}
            {!showHome && (
              <>
                <Link className="header-link" to="/about">
                  About
                </Link>{" "}
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
