import { createContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const SnackContext = createContext({ snacks: [], setSnacks: () => {} });

export const SnackContextProvider = (props) => {
  const [snacks, setSnacks] = useState([]);
  useEffect(() => {
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: "snack-driven-development.firebaseapp.com",
      projectId: "snack-driven-development",
      storageBucket: "snack-driven-development.appspot.com",
      messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
      appId: process.env.REACT_APP_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // eslint-disable-next-line no-unused-vars
    const analytics = getAnalytics(app);
  }, []);
  return (
    <SnackContext.Provider value={{ snacks, setSnacks }}>
      {props.children}
    </SnackContext.Provider>
  );
};
